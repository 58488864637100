<template>
  <div>
    <div class="row">
      <div class="col-md-12 mx-auto">
        <h4 class="text-center">{{ submit }}</h4>
        <div class="card mb-5">
          <div class="card-body">
            <div class="d-flex flex-column">
              <div v-if="router.name" class="form-group">
                <input
                  v-model="router.name"
                  type="text"
                  class="form-control"
                  placeholder="Router Name"
                />
              </div>
              <div v-if="router.id" class="form-group">
                <div>
                  <label class="col-sm-12 col-form-label">Serial number:</label>
                  <input
                    v-model="router.serial_number"
                    type="text"
                    class="form-control"
                    placeholder="Serial number"
                    disabled
                  />
                </div>
                <div>
                  <label class="col-sm-12 col-form-label">IMEI:</label>
                  <input
                    v-model="router.imei"
                    type="text"
                    v-mask="'XX-XX-XX-XX-XX-XX'"
                    class="form-control"
                    placeholder="IMEI"
                  />
                </div>
              </div>
              <div v-else class="form-group row">
                <label class="col-sm-12 col-form-label"
                  >Enter number of routers</label
                >
                <div class="col-sm-12">
                  <input
                    v-model="router.routers"
                    type="number"
                    class="form-control"
                    placeholder="Number of Routers"
                    min="1"
                  />
                </div>
              </div>
              <!-- <button @click="addRouter" class="btn btn-success pd-x-20">
                {{ submit }}
              </button> -->
            </div>
          </div>
          <div class="my-3 text-center">
            <button
              @click="addRouter"
              class="btn btn-success font-weight-bold w-50 text-uppercase"
            >
              {{ submit }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
export default {
  name: "RouterForm",
  directives: {
    mask
  },
  data() {
    return {
      responseErrors: {},
      submitting: false,
      submit: "Add New Router(s)"
    };
  },
  props: {
    router: {
      type: Object,
      default() {
        return {
          routers: 1,
          calledstationid: "",
          name: "",
          nas_identifier: "",
          active: 1,
          type: 1,
          location: "",
          serial_number: ""
        };
      }
    }
  },
  mounted() {
    this.toggleTitle();
  },
  methods: {
    toggleTitle() {
      if (this.router.id) {
        this.submit = "Edit Router";
      }
    },
    async addRouter() {
      if (this.router.id) {
        this.updateRouter();
      } else {
        this.submitting = true;
        this.submit = "Adding Routers...";
        const router = {
          routers: this.router.routers,
          location: "Manufacturer"
        };
        await axios
          .post(`v1/routers/`, router)
          .then(response => {
            if (response.status === 201) {
              this.$emit("hide-new-router-modal");
              this.$emit("get-routers", "Manufacturer", true);
              if (response.data.detail) {
                this.$swal("Sucsess", `${response.data.detail}`, "success");
              } else {
                this.$swal("Sucsess", "Routers Added Successfully", "success");
              }
            }
          })
          .catch(error => {
            console.log(error);
            if (error.response) {
              if (error.response.data.detail) {
                this.$swal("Error", `${error.response.data.detail}`, "error");
              }
              this.responseErrors = error.response.data;
              this.loading = false;
              this.submit = "Add New Router(s)";
            }
          });
      }
    },
    async updateRouter() {
      this.submitting = true;
      delete this.router["partner"];

      await axios
        .patch(`v1/routers/${this.router.id}/`, this.router)
        .then(response => {
          if (response.status === 200) {
            this.$emit("hide-edit-router-modal");
            this.$swal("Success", "Router updated successfully", "success");
            this.$emit("get-router", this.$route.params.id);
            // this.$emit("hide-edit-form");
          }
        })
        .catch(error => {
          if (error.response) {
            this.responseErrors = error.response.data.errors;
            this.$swal("Error", `${this.responseErrors}`, "warning");
            this.loading = false;
          }
        });
    }
  }
};
</script>