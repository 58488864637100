<template>
  <div>
    <div class="container-fluid pb-3 pt-5 pt-md-6">
      <div class="row col-12 page-head">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb page-head-nav">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard' }">Home</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'routers' }">Routers</router-link>
            </li>
            <li class="breadcrumb-item">Router Details</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="user-profile col-md-4 mb-3">
          <div class="user-display">
            <div class="user-display-bg">
              <img src="/img/deal.png" alt="Profile Background" />
            </div>
            <hr />
            <div
              class="d-flex justify-content-around align-items-center pb-4 pt-1 px-3"
              v-if="loggedInUser.role === adminRole"
            >
              <div>
                <b-button v-b-modal.edit-router-modal variant="success"
                  >Update Router</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 mb-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="scroll">
                <h3 class="text-uppercase mb-3">Router Details</h3>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Serial Number</th>
                      <td>{{ router.serial_number }}</td>
                    </tr>
                    <!-- <tr>
                      <th>Nas Identifier</th>
                      <td>{{ router.nasidentifier }}</td>
                    </tr> -->
                    <tr v-if="router.partner">
                      <th>Partner</th>
                      <td>
                        <router-link
                          :to="{
                            name: 'partner-details',
                            params: { id: router.partner.id },
                          }"
                        >
                          {{ router.partner.name }}
                        </router-link>
                      </td>
                    </tr>
                    <tr v-if="router.sme">
                      <th>Sme</th>
                      <td>
                        {{ router.sme.name }}
                      </td>
                    </tr>
                    <tr v-if="router.has_kiosk">
                      <th>Kiosk</th>
                      <td>
                        <router-link
                          :to="{
                            name: 'kiosk-details',
                            params: { id: router.router_kiosk.id },
                          }"
                        >
                          {{ router.router_kiosk.serial_number }}
                        </router-link>
                      </td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>
                        <span v-if="router.active" class="text-success"
                          >Active</span
                        >
                        <span v-else class="text-warning">Inactive</span>
                      </td>
                    </tr>
                    <tr v-if="router.last_contact">
                      <th>Last Contact</th>
                      <td>
                        {{ new Date(router.last_contact).toLocaleString() }}
                      </td>
                    </tr>
                    <tr v-if="router.last_contact_ip">
                      <th>Last Contact</th>
                      <td>{{ router.last_contact_ip }}}</td>
                    </tr>
                    <tr v-if="router.imei">
                      <th>IMEI</th>
                      <td>{{ router.imei }}</td>
                    </tr>
                    <tr>
                      <th>Created</th>
                      <td>{{ new Date(router.created).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center py-3">
                <b-button @click="show = true" class="btn btn-primary ml-2"
                  >Print QR</b-button
                >
              </div>
              <b-modal hide-backdrop v-model="show" title="Print Qr">
                <p>
                  <RouterQrComponent :item="router" />
                </p>
                <template v-slot:modal-footer>
                  <div class="w-100">
                    <b-button
                      variant="success"
                      size="md"
                      class="float-right"
                      @click="show = false"
                    >
                      Close
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="edit-router-modal" ref="edit-router" hide-footer hide-backdrop>
      <RouterForm
        :router="router"
        @get-router="getRouter"
        @hide-edit-router-modal="hideEditRouterModal"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import RouterQrComponent from "@/components/Routers/QrCode";
import RouterForm from "@/components/Routers/RouterForm";
export default {
  components: { RouterQrComponent, RouterForm },
  data() {
    return {
      router: {},
      show: false,
      adminRole: "ared_admin",
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  mounted() {
    this.getRouter(this.$route.params.id);
  },
  methods: {
    hideEditRouterModal() {
      this.$refs["edit-router"].hide();
    },
    async getRouter(id) {
      await axios
        .get(`v1/routers/${id}/`)
        .then((response) => {
          if (response.status === 200) {
            this.router = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
  },
};
</script>

<style></style>
